import Swiper from "swiper";
import { Navigation, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.css";

window.Swiper = Swiper;
window.Navigation = Navigation;
window.Scrollbar = Scrollbar;

window.swiper = new Swiper(".swiper-collection-slider-big", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 4,
        },
        1280: {
            slidesPerView: 5,
        },
    },
});

window.swiper = new Swiper(".swiper-collection-slider-badge", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 5,
    spaceBetween: 20,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 4,
        },
        1280: {
            slidesPerView: 5,
        },
    },
});

window.swiper = new Swiper(".swiper-collection-slider-round", {
    modules: [Navigation],
    slidesPerView: 5,
    spaceBetween: 20,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 4,
        },
        1280: {
            slidesPerView: 5,
        },
    },
});

window.swiper = new Swiper(".swiper-product-slider", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },

    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 4,
        },
        1280: {
            slidesPerView: 5,
        },
    },
});

window.swiper = new Swiper(".swiper-product-slider-cart", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 3,
    spaceBetween: 5,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
});

window.swiper = new Swiper(".swiper-product-images-bullets", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 6,
    spaceBetween: 5,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
});
