import { resetSlideshowScroll } from "./slideshow.mjs";

/* Utility Functions */
function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

function setQueryParam(param, value) {
    const url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.history.replaceState({}, "", url.toString());
}

function getLocalStorageItem(key) {
    return localStorage.getItem(key);
}

function setLocalStorageItem(key, value) {
    localStorage.setItem(key, value);
}

function selectAll(selector) {
    return Array.from(document.querySelectorAll(selector));
}

function select(selector) {
    return document.querySelector(selector);
}

function parseJSON(jsonString) {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        console.error("Failed to parse JSON:", e);
        return null;
    }
}

/* Data Retrieval and Initialization */
function getVariantMap() {
    const variantElement = select("#variant-map");
    return variantElement ? parseJSON(variantElement.textContent.trim()) : {};
}

function initializeSelections(variantMap, colorButtons, sizeButtons) {
    const urlVariant = getQueryParam("variant");
    const urlColor = getQueryParam("activeColor");
    const urlSize = getQueryParam("activeSize");

    let activeVariantId = urlVariant || null;
    let activeColor = urlColor || getLocalStorageItem("activeColor") || null;
    let activeSize = urlSize || getLocalStorageItem("activeSize") || null;

    if (activeVariantId && variantMap) {
        const variantEntry = Object.entries(variantMap).find(
            ([, details]) => details.id.toString() === activeVariantId
        );
        if (variantEntry) {
            const [variantColor, variantSize] = variantEntry[0].split("|");
            activeColor = variantColor;
            activeSize = variantSize;
        }
    }

    if (!activeColor && colorButtons.length > 0) {
        activeColor = colorButtons[0].getAttribute("data-color");
    }

    if (!activeSize && sizeButtons.length > 0) {
        // Select the first available size if no size is set
        const firstAvailableSize = sizeButtons.find((button) => !button.disabled);
        activeSize = firstAvailableSize ? firstAvailableSize.getAttribute("data-size") : null;
    }

    return { activeVariantId, activeColor, activeSize };
}

/* UI Update Functions */
function updateActiveState(buttons, attribute, value) {
    buttons.forEach((button) => {
        if (button.getAttribute(attribute) === value) {
            button.classList.add("active");
            button.dataset.active = "true";
        } else {
            button.classList.remove("active");
            button.dataset.active = "false";
        }
    });
}

function setImages(imagesOrder) {
    if (imagesOrder) {
        const arr = imagesOrder.split(",").map((item) => parseInt(item));
        const images = document.querySelectorAll(".js-product-image");

        images.forEach((img, index) => {
            const isVisible = arr.includes(index + 1);
            img.parentElement.classList.toggle("hidden", !isVisible);
            if (isVisible && img.tagName === "SOURCE") {
                // Add the skeleton class
                img.classList.add('skeleton');
                const source = img.getAttribute("data-src");
                // Create a new image object to preload the image
                const preloadImg = new Image();
                preloadImg.src = source;
                preloadImg.onload = () => {
                    // Once the image has loaded, set the src attribute and remove the skeleton class
                    img.src = source;
                    img.classList.remove('skeleton');
                    img.parentElement.load();
                };
            }
        });
    }
    resetSlideshowScroll();
}

function updateStockInfo(variantDetails) {
    const addToCartButton = select(".js-add-to-cart-single");
    const inStockText = select(".js-in-stock-product-page");
    const outStockText = select(".js-out-stock-product-page");

    if (!variantDetails || !addToCartButton) {
        return;
    }

    const { id, stock } = variantDetails;

    addToCartButton.setAttribute("data-id", id);
    addToCartButton.setAttribute("data-stock", stock);

    const stockNumber = Number(stock);

    if (stockNumber > 0) {
        inStockText?.classList.remove("hidden");
        outStockText?.classList.add("hidden");
    } else {
        inStockText?.classList.add("hidden");
        outStockText?.classList.remove("hidden");
    }

    setQueryParam("variant", id);
}

/* Selection Handlers */
function handleColorSelection(color, variantMap, colorButtons, sizeButtons) {
    updateActiveState(colorButtons, "data-color", color);
    setLocalStorageItem("activeColor", color);

    const availableSizes = Object.keys(variantMap)
        .filter((key) => key.startsWith(`${color}|`))
        .map((key) => key.split("|")[1]);

    sizeButtons.forEach((button) => {
        const size = button.getAttribute("data-size");
        if (availableSizes.includes(size)) {
            button.disabled = false;
            button.classList.remove("disabled");
        } else {
            button.disabled = true;
            button.classList.add("disabled");
        }
    });

    let activeSize = getLocalStorageItem("activeSize");
    if (!availableSizes.includes(activeSize)) {
        activeSize = availableSizes[0] || null;
        setLocalStorageItem("activeSize", activeSize);
    }

    const variantKey = `${color}|${activeSize}`;
    const variantDetails = variantMap[variantKey];

    const activeColorButton = Array.from(colorButtons).find((button) => button.getAttribute("data-color") === color);
    if (activeColorButton) {
        const imgOrder = activeColorButton.getAttribute("data-images");
        setImages(imgOrder);
    }

    updateStockInfo(variantDetails);

    // If there are no size variants, update the variant directly
    if (sizeButtons.length === 0) {
        const variantKey = `${color}|`;
        const variantDetails = variantMap[variantKey];
        if (variantDetails) {
            setQueryParam("variant", variantDetails.id);
            const addToCartButton = select(".js-add-to-cart-single");
            addToCartButton.setAttribute("data-id", variantDetails.id);
        }
    }

    return activeSize;
}

function handleSizeSelection(size, variantMap, colorButtons, sizeButtons) {
    updateActiveState(sizeButtons, "data-size", size);
    setLocalStorageItem("activeSize", size);

    const activeColor = getLocalStorageItem("activeColor");

    const variantKey = `${activeColor}|${size}`;
    const variantDetails = variantMap[variantKey];

    updateStockInfo(variantDetails);
}

/* Event Listeners Setup */
function setupEventListeners(variantMap, colorButtons, sizeButtons) {
    colorButtons.forEach((button) => {
        button.addEventListener("click", () => {
            const selectedColor = button.getAttribute("data-color");
            const activeSize = handleColorSelection(selectedColor, variantMap, colorButtons, sizeButtons);
            updateActiveState(sizeButtons, "data-size", activeSize);
        });
    });

    sizeButtons.forEach((button) => {
        button.addEventListener("click", () => {
            if (!button.disabled) {
                const selectedSize = button.getAttribute("data-size");
                handleSizeSelection(selectedSize, variantMap, colorButtons, sizeButtons);
            }
        });
    });
}

function setupAddToCartHandler() {
    const addToCartButton = select(".js-add-to-cart-single");
    const messageElement = select("#js-message-missing-options");

    addToCartButton?.addEventListener("click", () => {
        const variantId = addToCartButton.getAttribute("data-id");

        if (variantId) {
            messageElement?.classList.add("hidden");
        } else {
            messageElement?.classList.remove("hidden");
        }
    });
}


/* Initialization Function */
export default function initializeProductVariantSelector() {
    if (!window.location.pathname.includes("/products/")) return;

    const variantMap = getVariantMap();
    const colorButtons = selectAll(".js-color-button");
    const sizeButtons = selectAll(".js-size-button");

    if (!variantMap || colorButtons.length === 0) return;

    const { activeColor, activeSize } = initializeSelections(variantMap, colorButtons, sizeButtons);

    updateActiveState(colorButtons, "data-color", activeColor);
    updateActiveState(sizeButtons, "data-size", activeSize);

    const variantKey = `${activeColor}|${activeSize}`;
    const variantDetails = variantMap[variantKey];

    // Get the color button with the active color
    const activeColorButton = Array.from(colorButtons).find(
        (button) => button.getAttribute("data-color") === activeColor
    );
    if (activeColorButton) {
        const imgOrder = activeColorButton.getAttribute("data-images");
        setImages(imgOrder);
    }

    updateStockInfo(variantDetails);

    setupEventListeners(variantMap, colorButtons, sizeButtons);
    setupAddToCartHandler();
}

/* Execute Initialization on Window Load */
window.addEventListener("load", initializeProductVariantSelector);
