class Cart {
    constructor() {
        //Cart items
        this.items = [];

        //Clone of cart item
        this.clone = document.getElementById("cart-item-clone").cloneNode(true);

        //The cart root element
        this.cart = document.getElementById("cart");

        //Element containing all the cart items
        this.container = document.querySelector(".js-cart-items");

        //Fetch cart items
        this.getCart().then(async (items) => {
            this.items = items;
            await this.renderCart();
        });

        //Open / close cart buttons
        this.toggleCartListeners();

        this.addToCartListeners();
    }

    //Fetches the current cart
    getCart() {
        return new Promise((resolve, reject) => {
            let locale = "";
            if (window.Shopify.locale !== "sv") {
                locale = "/" + window.Shopify.locale;
            }
            fetch(locale + "/cart.js")
                .then((res) => {
                    return res.json();
                })
                .then(async (data) => {
                    const items = data.items.map(async (item) => {
                        const priceData = await new Promise((resolve, reject) => {
                            //BE or DE

                            let locale = "";
                            if (window.Shopify.locale !== "sv") {
                                locale = "/" + window.Shopify.locale;
                            }

                            const url = locale + "/products/" + item.handle + ".js";
                            console.log();
                            fetch(url)
                                .then((res) => {
                                    return res.json();
                                })
                                .then((res) => {
                                    resolve({
                                        price: res.price / 100,
                                        originalPrice: res.compare_at_price / 100,
                                        title: res.title,
                                        options: res.options,
                                        variants: res.variants,
                                    });
                                })
                                .catch((err) => {
                                    reject(err.message);
                                });
                        });
                        item.price = priceData.price;
                        item.originalPrice = priceData.originalPrice;
                        item.title = priceData.title;
                        item.options = priceData.options;
                        item.variants = priceData.variants;

                        return item;
                    });

                    await Promise.all(items).then((values) => {
                        resolve(values);
                    });
                });
        });
    }

    //Toggle cart
    toggleCartListeners() {
        //Close cart
        this.cart.addEventListener("click", (e) => {
            if (e.target.classList.contains("open")) {
                this.cart.classList.remove("open");
                document.body.classList.remove("overflow-hidden");
            }
        });
    
        document.querySelector(".js-close-cart").addEventListener("click", () => {
            this.cart.classList.remove("open");
            document.body.classList.remove("overflow-hidden"); 
        });
    
        //Open cart button
        document.querySelectorAll(".cart-button").forEach((button) => {
            button.addEventListener("click", () => {
                this.cart.classList.add("open");
                document.body.classList.add("overflow-hidden");
            });
        });
    }

    addToCartListeners() {
        const buttons = [...document.querySelectorAll(".js-add-to-cart")];

        buttons.forEach((btn) => {
            btn.addEventListener("click", () => {
                const id = btn.getAttribute("data-id");
                const del = btn.getAttribute("delete");
                const currentStock = btn.getAttribute("data-stock");

                if (del) {
                    btn.parentElement.parentElement.classList.add("removing");
                    setTimeout(() => {
                        btn.parentElement.parentElement.remove();
                    }, 200);
                }
                this.addToCart(id, currentStock);
            });
        });
    }

    async renderCart() {
        //Clear element before appending items
        this.container.innerHTML = "";

        let totalPrice = 0;
        let total = 0;

        await this.items.forEach(async (item) => {
            totalPrice += item.price * item.quantity;
            total += item.quantity;

            const markup = await this.getCartItemMarkup(item);
            this.container.appendChild(markup);
        });

        //Update cart total
        document.querySelector(".js-cart-count").innerHTML = total;
        document.querySelector(".js-cart-count-open").innerHTML = total;

        let totalString = this.numberWithSpaces(totalPrice) + " " + this.getCurrency();
        document.querySelector(".js-total").innerHTML = totalString;

        let totalDiscount = 0;
        this.items.forEach((item) => {
            if (item.originalPrice) totalDiscount += (item.originalPrice - item.price) * item.quantity;
        });

        const discountAmountEement = document.querySelector(".js-discount");
        if (totalDiscount <= 0) {
            discountAmountEement.classList.add("hidden");
        } else {
            discountAmountEement.classList.remove("hidden");
            discountAmountEement.innerHTML = `Rabatt: ` + this.numberWithSpaces(totalDiscount) + this.getCurrency();
        }
    }

    async getCartItemMarkup(data) {
        const item = this.clone.cloneNode(true);

        item.querySelector(".js-title").innerHTML = data.title;

        item.setAttribute("data-stock", data.properties["Stock"]);

        const stockInfoElement = item.querySelector(".js-stock-info");

        if (data.properties["Stock"] > 0) {
            stockInfoElement.classList.add("text-green", "js-in-stock");
            stockInfoElement.textContent = stockInfoElement.dataset.inStockMessage;
        } else {
            stockInfoElement.classList.add("text-black", "js-out-stock");
            stockInfoElement.textContent = stockInfoElement.dataset.outOfStockMessage;
        }

        const priceEl = item.querySelector(".js-price");

        if (data.price < data.originalPrice) {
            priceEl.innerHTML = `
         <p class="text-black  font-bold line-through">${this.numberWithSpaces(
             data.originalPrice * data.quantity
         )} ${this.getCurrency()}</p>
         <p class="text-olive  font-bold">${this.numberWithSpaces(data.price * data.quantity)} ${this.getCurrency()}</p>
         `;
            priceEl.classList.add("flex");
            priceEl.classList.add("gap-2");
        } else {
            let priceElString = this.numberWithSpaces(data.price * data.quantity) + this.getCurrency();

            priceEl.innerHTML = priceElString;
        }

        item.querySelector(".js-image").src = data.image;
        item.querySelector(".js-quantity").value = data.quantity;

        if (data.variants.length > 1) {
            item.querySelector(".js-variant").innerHTML =
                data.options[0].name + ": " + data.variants.find((v) => v.id === data.variant_id).title;
        }

        item.id = "cartitem-" + data.id;
        item.querySelector(".js-quantity-container").setAttribute("data-id", data.id);

        item.querySelector(".js-quantity-add").addEventListener("click", () => {
            item.classList.add("loading");
            this.updateQuantity(data.id, data.quantity + 1);
        });

        item.querySelector(".js-quantity-remove").addEventListener("click", () => {
            item.classList.add("loading");
            if (data.quantity - 1 === 0) item.classList.add("removing");
            this.updateQuantity(data.id, data.quantity - 1);
        });

        item.querySelector(".js-remove-item").addEventListener("click", () => {
            item.classList.add("loading");
            item.classList.add("removing");
            this.updateQuantity(data.id, 0);
        });

        item.removeAttribute("id");
        item.classList.remove("hidden");

        return item;
    }

    getCurrency() {
        return window.location.href.includes("/en") || window.location.href.includes("/de") ? " €" : " kr";
    }

    updateQuantity(id, quantity) {
        const data = {
            updates: {
                [id]: quantity,
            },
        };

        fetch(window.Shopify.routes.root + "cart/update.js", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.getCart().then(async (items) => {
                    this.items = items;
                    await this.renderCart();
                });
            });
    }

    numberWithSpaces(x) {
        return x
            .toFixed()
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    addToCart(id, currentStock) {
        const data = {
            items: [
                {
                    id: id,
                    quantity: 1,
                    properties: {
                        Stock: currentStock,
                    },
                },
            ],
        };

        fetch(window.Shopify.routes.root + "cart/add.js", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            if (res.status !== 422) {
                this.getCart().then(async (items) => {
                    this.items = items;
                    await this.renderCart();
                    this.cart.classList.add("open");
                });
            } else {
                console.error("Error! To many of this product in cart or sth else..");
            }
        });
    }
}

export default function cart() {
    const cart = new Cart();
}
