const redirects = [
    {
        from: "/products/axelvaska-elenor",
        to: "/products/axelvaska-elenor?color=Tan",
    },
    {
        from: "/products/handvaska-norma",
        to: "/products/handvaska-norma?color=Tan",
    },
    {
        from: "/products/axelvaska-udden",
        to: "/products/axelvaska-udden?color=Tan",
    },
    {
        from: "/products/tote-iris",
        to: "/products/tote-iris?color=Brun",
    },
    {
        from: "/products/axelvaska-judith",
        to: "/products/axelvaska-judith?color=Svart",
    },
    {
        from: "/products/axelvaska-marie",
        to: "/products/axelvaska-marie?color=Tan",
    },
    {
        from: "/products/tote-rutt",
        to: "/products/tote-rutt?color=Tan",
    },
    {
        from: "/products/laptopvaska-inga",
        to: "/products/laptopvaska-inga?color=Brun",
    },
    {
        from: "/products/handvaska-britta",
        to: "/products/handvaska-britta?color=Tan",
    },
    {
        from: "/products/weekendvaska-barnholm",
        to: "/products/weekendvaska-barnholm-color-brun-color-brun",
    },
    {
        from: "/products/planbok-assar",
        to: "/products/planbok-assar?color=Svart",
    },
    {
        from: "/products/tote-duett",
        to: "/products/tote-duett?color=Tan",
    },
    {
        from: "/products/weekendvaska-kalahatt",
        to: "/products/weekendvaska-kalahatt?color=Tan",
    },
    {
        from: "/products/portfolj-jon",
        to: "/products/portfolj-jon?color=Brun",
    },
    {
        from: "/products/planbok-oskar",
        to: "/products/planbok-oskar?color=Tan",
    },
    {
        from: "/products/axelvaska-grunna",
        to: "/products/axelvaska-grunna?color=Brun",
    },
    {
        from: "/products/ryggsack-yngve",
        to: "/products/ryggsack-yngve?color=Tan",
    },
    {
        from: "/products/axelvaska-grunna-twin",
        to: "/products/axelvaska-grunna-twin?color=Brun",
    },
];

function removeTrailingSlash(str) {
    return str.replace(/\/+$/, "");
}
export default () => {
    redirects.forEach((data) => {
        const { from, to } = data;
        const current = removeTrailingSlash(window.location.pathname) + window.location.search;

        window.location.pathname + "/" + window.location.search;
        if (current === from) {
            console.log(window.location.pathname);
            window.location.href = to;
        }
    });
};
